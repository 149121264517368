











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, Reports } from '@/store';
import DailyCommissioned from '@/pages/reports/DailyCommissioned.vue';
import WeeklyCommissioned from '@/pages/reports/WeeklyCommissioned.vue';
import moment from 'moment-timezone';

@Component({
    components: {
        DailyCommissioned,
        WeeklyCommissioned
    }
})
export default class InstallationsReport extends Vue {

    @Global.State('timezone') projectTimezone;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.State('reportsList') devices;

    clicked = 0;
    loading = true;
    weekly_timezone = {    
        from_timestamp: null,
        from_timezone: null,
        to_timestamp: null,
        to_timezone: null
    };

    created(){
        this.setPageTitle('Installations Report');
        this.loading = true;
        this.weekly_timezone.from_timestamp = moment().subtract(7, 'days').valueOf();
        this.weekly_timezone.from_timezone = moment(this.weekly_timezone.from_timestamp).tz(this.projectTimezone).format('DD/MM/YYYY');
        this.weekly_timezone.to_timestamp = moment().subtract(1, 'days').valueOf();
        this.weekly_timezone.to_timezone = moment(this.weekly_timezone.to_timestamp).tz(this.projectTimezone).format('DD/MM/YYYY');
        this.loading = false;
    }

    setClicked(clicked){
        this.clicked = clicked;
    }

    get title(){
        return this.clicked === 0  
            ? `${this.$t('Date')}: ${this.weekly_timezone.to_timezone}`
            : `${this.$t('Date')}: ${this.weekly_timezone.from_timezone} - ${this.weekly_timezone.to_timezone}`;
    }
}
